import React from 'react';
import { Card, Text, IconButton } from 'theme-ui';
import { FaEnvelope, FaLocationArrow } from 'react-icons/fa';
import Section from '@components/Section';
import useSiteMetadata from '@helpers-blog/useSiteMetadata';

const ContactInfo = () => {
  const { address, email } = useSiteMetadata();

  return (
    <Section aside title="Get in touch">
      <Card variant="paper">
        <Text variant="p">
          Interested in working together?
          <br />
          Or wanna chit-chat?
        </Text>
        {email && (
          <Text>
            <IconButton variant="simple" role="presentation">
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
        )}
        {address && (
          <Text>
            <IconButton variant="simple" role="presentation">
              <FaLocationArrow />
            </IconButton>
            {address}
          </Text>
        )}
      </Card>
    </Section>
  );
};

export default ContactInfo;
