import React from 'react';
import { Layout, Stack, Main, Sidebar } from '@layout';
import PageTitle from '@components/PageTitle';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';
import ContactForm from '@widgets/ContactForm';
import ContactInfo from '@widgets/ContactInfo';

const PageContact = (props) => (
  <Layout {...props}>
    <Seo title="Contact" />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect!"
          subheader="Looking for any business query, or just want to have some chit chat? Send your contact information and I will get to you soon."
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
);

export default PageContact;
