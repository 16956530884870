import { useState, useEffect, useCallback } from 'react';

const useForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [target, setTarget] = useState({});
  const [success, setSuccess] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setTarget(e.target);
    setSubmitting(true);
  };

  const sendValues = useCallback(() => {
    const form = new FormData(target);
    const isDemo = target.getAttribute('demo');

    // Mimicking form submission for demos
    if (isDemo) {
      setTimeout(() => {
        setSuccess(true);
        setSubmitting(false);
      }, 1500);
      return;
    }

    // Real form submission
    fetch(target.action, {
      method: target.method || 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(Object.fromEntries(form)),
    })
      .then(() => {
        target.reset();
        setSuccess(true);
      })
      .catch((error) => {
        console.log({ error });
        setSuccess(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [target]);

  useEffect(() => {
    if (submitting) {
      sendValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, sendValues]);

  const reset = useCallback(() => {
    setSubmitting(false);
    setSuccess(undefined);
  }, []);

  return {
    handleSubmit,
    submitting,
    success,
    reset,
  };
};

export default useForm;
